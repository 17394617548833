import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {delay} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlaceQuestionService {

  constructor(private http: HttpClient) {
  }

  storeQuestion(question: string) {
    return this.http.post('questions/create', {question});
  }

  getUserQuestions() {
    return this.http.get('questions/user');
  }

  getAllUnanswered() {
    return this.http.get('questions/unanswered');
  }

  placeAnswer(id: number, answer: string) {
    return this.http.post(`questions/${id}/answer`, {answer});
  }
}
