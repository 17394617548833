import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Article} from './article';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  constructor(private http: HttpClient) {
  }

  getAll(): Observable<Article[]> {
    return this.http.get<Article[]>('articles');
  }

  getByUrl(url: string | null) {
    return this.http.get<Article>('articles/' + url);
  }

  create(title: string, short: string, body: string, keywords: string, meta: string, file, url) {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('short', short);
    formData.append('body', body);
    formData.append('keywords', keywords);
    formData.append('meta', meta);
    formData.append('url', url);
    formData.append('file', file, file.name);

    return this.http.post<Article>('articles/create', formData);
  }

  comment(id: number | undefined, comment: string) {
    return this.http.post(`article/${id}/comment`, {comment});
  }

  getComments(id: number | undefined) {
    return this.http.get(`article/${id}/comment`);
  }

  delete(id) {
    return this.http.delete(`article/${id}`);
  }
}
